<template>
  <div id="data-list-list-view">
    <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click="$router.push('/Patient')"
    />

    <patient-Tab class="mt-4" :mainTabName="'PendingMedicalDocumentation'" />
    <div>
      <vx-card class="flex-1" v-bind:title="$t('Pending medical Documents')">
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="PendingMedicalDocumentations"
        >
          <template slot="thead">
            <vs-th> {{ $t("Doctor") }}</vs-th>
            <vs-th> {{ $t("Request Type") }}</vs-th>
            <vs-th> {{ $t("Date") }}</vs-th>
            <vs-th> {{ $t("Doctor Comment") }}</vs-th>

            <vs-th> {{ $t("Prescription Note") }}</vs-th>

            <vs-th> {{ $t("Report Note") }}</vs-th>
            <vs-th v-if="$acl.check('adminPatientAgent')"> {{ $t("Upload") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p v-if="tr.Doctor" class="product-name font-medium truncate">
                    {{ tr.Doctor.Email }}
                  </p>
                </vs-td>
                <vs-td>
                  <p
                    v-if="tr.DoctorRequestType"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.DoctorRequestType.Name }}
                  </p>
                </vs-td>

                <vs-td>
                  <p v-if="tr.Date" class="product-name font-medium truncate">
                    {{ tr.Date }}
                  </p>
                </vs-td>
                <vs-td>
                  <p
                    v-if="tr.PatientReservationSession"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.PatientReservationSession.DoctorComment }}
                  </p>
                </vs-td>
                <vs-td>
                  <p
                    v-if="tr.PatientReservationSession"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.PatientReservationSession.PrescriptionNote }}
                  </p>
                </vs-td>
                <vs-td>
                  <p
                    v-if="tr.PatientReservationSession"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.PatientReservationSession.RequestedReportNote }}
                  </p>
                </vs-td>

                <vs-td v-if="$acl.check('adminPatientAgent')">
                  <feather-icon
                    icon="UploadIcon"
                    @click.stop="uploadAttachment(tr)"
                    class="cursor-pointer"
                  ></feather-icon>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button type="border" color="danger" :to="{ name: 'Patient' }">{{
            $t("back")
          }}</vs-button>
        </div>
      </div>
    </div>

    <vs-popup v-if="ShowAttachmentPopup" title="" :active.sync="ShowAttachmentPopup">
      <div id="UPMMReport" class="flex items-center justify-center inset-0 ...">
        <div class="p-8" style="width: 70%; background-color: white; border-radius: 10px">
          <div class="vx-card__title mb-4"></div>

          <div class="vx-col mx-auto self-center d-theme-dark-bg">
            <div class="vx-row text-center">
              <v-select
                class="w-full mb-3"
                label="Name"
                v-model="documentModel.DocumentID"
                :options="DocumentTypes"
                :reduce="(ID) => ID.ID"
                placeholder="Document Type"
              />
            </div>
            <div class="vx-row text-center">
              <v-select
                v-if="documentModel.DocumentID == 1"
                class="w-full"
                label="Name"
                v-model="documentModel.DocumentTypeID"
                :options="ScanTypes"
                :reduce="(ID) => ID.ID"
                placeholder="Scan Type"
              />
            </div>
            <div class="vx-row text-center">
              <v-select
                v-if="documentModel.DocumentID == 2"
                class="w-full"
                label="Name"
                v-model="documentModel.DocumentTypeID"
                :options="AnalysisTypes"
                :reduce="(ID) => ID.ID"
                placeholder="Analysis Type"
              />
            </div>
            <div class="vx-row text-center">
              <v-select
                v-if="documentModel.DocumentID == 3"
                class="w-full"
                label="Name"
                v-model="documentModel.DocumentTypeID"
                :options="ReportTypes"
                :reduce="(ID) => ID.ID"
                placeholder="Report Type"
              />
            </div>
            <div class="vx-row mb-3">
              <vs-input
                :label="$t('Description')"
                v-model="documentModel.Description"
                class="mt-3 w-full"
                :data-vv-as="$t('Description')"
                name="Description"
              />
            </div>
          </div>

          <div class="vx-col mb-3 mx-auto self-center d-theme-dark-bg">
            <div class="vx-row text-center flex items-center">
              <vs-upload
                class="w-full"
                automatic
                :limit="1"
                :action="baseURL + 'api/Common/UploadFile'"
                :headers="UploadRequestHeader"
                @on-success="successUpload"
              />
            </div>
            <div class="vx-row text-center">
              <vs-button
                class="w-full"
                color="primary"
                v-if="uploaded"
                @click="AddAttachmnet"
                >{{ $t("Procced") }}</vs-button
              >
            </div>
            <div class="vx-row text-center">
              <vs-button
                class="w-full"
                type="line"
                color="black"
                @click.stop="$emit('closePop')"
                >{{ $t("Back") }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
// import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
// import moment from "moment";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";
import patientTab from "@/views/tabs/patientTab.vue";
export default {
  components: {
    VuePerfectScrollbar,
    Datepicker,
    patientTab,
    // vSelect,
  },

  computed: {
    PendingMedicalDocumentations() {
      return this.$store.state.patientList.PendingMedicalDocumentations;
    },
    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },
    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },
    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
  },
  data() {
    return {
      ShowAttachmentPopup: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
        years: [],
      },
      uploaded: false,
      patientModel: {},
      isMounted: false,
      baseURL: domain,
      rowDataForDelete: {},
      experienceModel: {},
      activePrompt: false,
      documentModel: {},
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Course/UploadFile",
    };
  },
  methods: {
    uploadAttachment(data) {
      this.ShowAttachmentPopup = true;
      this.documentModel.DoctorID = data.DoctorID;
      this.documentModel.PatientReservationSessionID = data.PatientReservationSessionID;
      this.documentModel.IsRequest = true;
      this.documentModel.PatientMedicalReportRequestID = data.ID;
      this.documentModel.PatientID = this.$route.params.ID;
    },
    successUpload(event) {
      this.documentModel.AttachPath = event.target.responseText;
      window.showAddSuccess();
      this.uploaded = true;
    },
    AddAttachmnet() {
      debugger;
      this.$store
        .dispatch("patientList/AddPatientMedicalDocument", this.documentModel)
        .then((res) => {
          if (res.status == 200) {
            window.showAddSuccess();

            this.ShowAttachmentPopup = false;
            this.uploaded = false;
          }
        })
        .catch(() => {
          window.showError();
          window.showError(err.response.data.message);
        });
      this.documentModel = {};
    },
    getPendingMedicalDocumentation(id) {
      var model = {};
      model.PatientId = id;
      model.stausID = 1; //pending
      this.$vs.loading();

      this.$store
        .dispatch("patientList/getPendingMedicalDocumentation", model)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");
    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }

    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");
    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$store.dispatch("patientList/GetPatient", ID).then((res) => {
        this.patientModel = res.data.Data;
        this.$store.commit("SET_SetTitle", "" + this.patientModel.NameEN);
      });
      this.getPendingMedicalDocumentation(ID);
    }
  },
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
